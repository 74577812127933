$theme-colors: (
  "primary": #213458,
  "success": #4bb543,
  "info": #b8cef9,
);

@import "react-calendar/dist/Calendar.css";
@import "react-date-picker/dist/DatePicker.css";
@import "react-dropzone-uploader/dist/styles.css";
@import "../node_modules/bootstrap/scss/bootstrap";

.react-datepicker__input-container {
  input {
    height: 41.24px;
  }
}

.amplify-autocomplete__menu  {
  z-index: 9999;
}

* {
  --bs-dropdown-item-padding-y: 10px;
}

@media print {
  @page {
    margin: 0;
  }
  body {
    margin: 1.6cm;
  }

  .noprint {
    display: none;
  }

  .print-layout-navcolumn {
    margin-left: 0 !important;
  }
}

[data-amplify-theme] {
  --amplify-components-button-primary-background-color: #213458;
  --amplify-components-checkbox-icon-background-color: #213458;
  --amplify-components-badge-success-background-color: #4bb543;
}

// // for fixing checkboxes
.amplify-checkboxfield {
  img,
  svg {
    vertical-align: unset;
  }
}

// // for fixing autocomplete because flex-grow does not work
.amplify-autocomplete {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 100px);
  padding: 0 15px 15px 15px;
  max-width: 1000px;
  flex: 1;
}
